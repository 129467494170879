import moment from 'moment';

export const formattedDate = (date: string) => {
    return moment(date).format('DD.MM.YY');
};

export const formattedDateLong = (date: string) => {
    return moment(date).format('DD.MM.YYYY');
};

export const formattedDateTime = (date: string) => {
    const rawDate = moment(date).format('DD.MM.YYYY, H:mm');
    return rawDate.substring(rawDate.length - 5).startsWith('1')
        ? rawDate
        : rawDate.substring(0, rawDate.length - 5) +
              ' 0' +
              rawDate.substring(rawDate.length - 4);
};

export const calculateRemainingDays = (expiryDate: string | null) => {
    const end = moment(expiryDate);

    const remainingDays = end.diff(moment(), 'days') + 1;

    if (isNaN(remainingDays) || remainingDays < 0) {
        return 0;
    }

    return remainingDays;
};

export const calculateRuntimePercentage = (
    startDate: string | null,
    expiryDate?: string | null,
) => {
    if (!startDate || !expiryDate || isDateInFuture(startDate)) {
        return 0;
    }

    const end = moment(expiryDate);
    const start = moment(startDate);
    const delta = end.diff(moment(), 'days');
    const fullRuntime = end.diff(start, 'days');

    if (delta > 0 && fullRuntime > 0) {
        return (100 / fullRuntime) * (fullRuntime - delta);
    }

    return 100;
};

export const isDateInFuture = (
    date: string | null,
    includeToday: boolean = false,
) => {
    if (!date) return;

    const formattedDate = moment(
        date,
        date.includes('.') ? 'DD.MM.YYYY' : undefined,
    );
    const today = moment().startOf('day');
    return includeToday
        ? formattedDate.isSameOrAfter(today)
        : formattedDate.isAfter(today);
};

export const isDateInPast = (
    date: string | null,
    includeToday: boolean = false,
) => {
    if (!date) return;

    const formattedDate = moment(
        date,
        date.includes('.') ? 'DD.MM.YYYY' : undefined,
    );
    const today = moment().startOf('day');
    return includeToday
        ? formattedDate.isSameOrBefore(today)
        : formattedDate.isBefore(today);
};

export const getFormattedFutureDate = (runtime: number) => {
    return formattedDateLong(moment().add(runtime, 'd').toString());
};
